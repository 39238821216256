import React, { Component } from 'react'
import { formatMinutesAndSeconds } from './utils'

export default class Playlist extends Component {
  state = {
    loading: false,
  }

  handleLoadStart = () => {
    this.setState({ loading: true })
  }

  handleLoadComplete = () => {
    this.setState({ loading: false })
  }

  render() {
    const { tracks } = this.props

    return (
      <div className="audio-player__tracks">
        {tracks.map((track, i) => this.renderTrackRow({ track, i }))}
      </div>
    )
  }

  renderTrackRow({ track, i }) {
    const { onPlayTrack } = this.props

    return (
      <div key={i} className="audio-player__track-row">
        <button
          onClick={() => onPlayTrack(i)}
          className="audio-player__track-play-button"
        >
          <div className="audio-player__track-play-button-circle">
            <i className="fas fa-play" />
          </div>
        </button>
        <div className="audio-player__track-name">{track.name}</div>
        <div className="audio-player__track-playtime">
          {formatMinutesAndSeconds(track.minutes, track.seconds)}
        </div>
      </div>
    )
  }
}
