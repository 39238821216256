import React, { Component } from 'react'

export default class Player extends Component {
  constructor(props) {
    super(props)
    this.playerRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { playing, playFromTime } = this.props
    const { playing: wasPlaying, playFromTime: wasPlayingFromTime } = prevProps

    if (playFromTime != wasPlayingFromTime) {
      this.seek(playFromTime)
    }

    if (playing && !wasPlaying) {
      this.play()
    } else if (!playing && wasPlaying) {
      this.pause()
    }
  }

  player = () => {
    return this.playerRef.current
  }

  play = () => {
    const player = this.player()
    if (player) player.play()
  }

  pause = () => {
    const player = this.player()
    if (player) player.pause()
  }

  seek = (time) => {
    const player = this.player()
    if (player) player.currentTime = time
  }

  handleLoadedMetadata = () => {
    const { onLoadedMetadata } = this.props

    if (onLoadedMetadata) {
      onLoadedMetadata(this.player())
    }
  }

  handleCanPlay = () => {
    const { playing, onLoadComplete } = this.props

    if (onLoadComplete) {
      onLoadComplete()
    }

    if (playing) {
      this.play()
    }
  }

  handleTimeUpdate = () => {
    const { onTimeUpdate } = this.props

    if (onTimeUpdate) {
      onTimeUpdate(this.player())
    }
  }

  render() {
    const { src, onLoadStart, onEnded } = this.props

    return (
      <audio
        ref={this.playerRef}
        src={src}
        onLoadStart={onLoadStart}
        onLoadedMetadata={this.handleLoadedMetadata}
        onCanPlay={this.handleCanPlay}
        onTimeUpdate={this.handleTimeUpdate}
        onEnded={onEnded}
      >
        Audio is not supported in your browser.
      </audio>
    )
  }
}
