export const formatSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const secondsInMinute = seconds % 60
  return formatMinutesAndSeconds(minutes, secondsInMinute)
}

export const formatMinutesAndSeconds = (minutes, seconds) => {
  const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds
  return `${minutes}:${paddedSeconds}`
}
