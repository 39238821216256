import React from 'react'
import ReactDOM from 'react-dom'
import AudioPlayer from '../javascript/audioPlayer/AudioPlayer'

const audioPlayerContainers = document.getElementsByClassName('audio-player')

Array.from(audioPlayerContainers).forEach((container) => {
  const { id } = container
  const tracks = JSON.parse(
    document.getElementById(`tracks-for-${id}`).innerHTML,
  )
  document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(<AudioPlayer tracks={tracks} />, container)
  })
})
