import React from 'react'
import Player from './Player'
import PlayerControls from './PlayerControls'
import Playlist from './Playlist'

export default class AudioPlayer extends React.Component {
  state = {
    currentTrackNumber: 0,
    currentTime: 0,
    currentDuration: 0,
    playing: false,
    loading: false,
  }

  constructor(props) {
    super(props)
  }

  playTrack = (trackNumber) => {
    this.setState({
      currentTrackNumber: trackNumber,
      playFromTime: 0,
      playing: true,
    })
  }

  play = () => {
    this.setState({ playing: true })
  }

  pause = () => {
    this.setState({ playing: false })
  }

  seek = (time) => {
    this.setState({ playFromTime: time })
  }

  handleLoadStart = () => {
    this.setState({ loading: true })
  }

  handleLoadedMetadata = (audioElement) => {
    this.setState({ currentDuration: audioElement.duration })
  }

  handleLoadComplete = () => {
    this.setState({ loading: false })
  }

  handleTimeUpdate = (audioElement) => {
    this.setState({ currentTime: audioElement.currentTime })
  }

  handleEnded = () => {
    const { tracks } = this.props
    const { currentTrackNumber } = this.state

    if (currentTrackNumber < tracks.length - 1) {
      this.setState({
        currentTrackNumber: currentTrackNumber + 1,
        playing: true,
        playFromTime: 0,
      })
    } else {
      this.setState({
        currentTrackNumber: 0,
        playing: false,
        playFromTime: 0,
      })
    }
  }

  currentTrack = () => {
    return this.props.tracks[this.state.currentTrackNumber]
  }

  render() {
    const { tracks } = this.props
    const {
      playing,
      loading,
      playFromTime,
      currentTrackNumber,
      currentTime,
      currentDuration,
    } = this.state
    const currentTrack = this.currentTrack()

    return (
      <div className="audio-player">
        <Player
          src={currentTrack.url}
          playing={playing}
          playFromTime={playFromTime}
          onLoadedMetadata={this.handleLoadedMetadata}
          onLoadStart={this.handleLoadStart}
          onLoadComplete={this.handleLoadComplete}
          onTimeUpdate={this.handleTimeUpdate}
          onEnded={this.handleEnded}
        />

        <PlayerControls
          loading={loading}
          playing={playing}
          track={currentTrack}
          currentTime={currentTime}
          currentDuration={currentDuration}
          onPlay={this.play}
          onPause={this.pause}
          onSeek={this.seek}
        />

        <Playlist
          tracks={tracks}
          currentTrackNumber={currentTrackNumber}
          onPlayTrack={this.playTrack}
        />
      </div>
    )
  }
}
