import React, { Component } from 'react'
import { formatSeconds } from './utils'

const clickTargetOffset = (e) => {
  const nativeEvent = e.nativeEvent
  const rect = nativeEvent.target.getBoundingClientRect()
  const clientX = nativeEvent.changedTouches ? nativeEvent.changedTouches[0].clientX : nativeEvent.clientX
  const clientY = nativeEvent.changedTouches ? nativeEvent.changedTouches[0].clientY : nativeEvent.clientY
  const x = clientX - rect.left
  const y = clientY - rect.top
  const xPercent = x / rect.width
  const yPercent = y / rect.height

  return { x, y, xPercent, yPercent }
}

export default class PlayerControls extends Component {
  state = {
    seeking: false,
    seekTime: 0,
  }

  handleSeekStart = (e) => {
    const { currentDuration } = this.props
    const { xPercent } = clickTargetOffset(e)

    this.setState({
      seeking: true,
      seekTime: xPercent * currentDuration,
    })
  }

  handleSeekMove = (e) => {
    const { currentDuration } = this.props
    const { xPercent } = clickTargetOffset(e)

    this.setState({
      seekTime: xPercent * currentDuration,
    })
  }

  handleSeekEnd = (e) => {
    const { currentDuration, onSeek } = this.props
    const { xPercent } = clickTargetOffset(e)

    this.setState({
      seeking: false,
      seekTime: xPercent * currentDuration,
    })

    if (onSeek) {
      onSeek(xPercent * currentDuration)
    }
  }

  render() {
    const { seeking, seekTime } = this.state
    const { track, currentTime, currentDuration, loading } = this.props

    if (!track) {
      return null
    }

    return (
      <div className="audio-player__player-row">
        {this.renderPlayPauseButton()}

        <div
          style={{ display: 'flex', flex: '1 0 auto', position: 'relative' }}
        >
          <div
            className="audio-player__tracker-container"
            onMouseDown={this.handleSeekStart}
            onMouseMove={this.handleSeekMove}
            onMouseUp={this.handleSeekEnd}
            onTouchStart={this.handleSeekStart}
            onTouchMove={this.handleSeekMove}
            onTouchEnd={this.handleSeekEnd}
            onTouchCancel={this.handleSeekEnd}
          >
            {this.renderTracker()}
          </div>

          <div className="audio-player__current">
            <div className="audio-player__current-track">
              {loading ? 'Loading…' : track.name}
            </div>

            <div className="audio-player__current-track-playtime">
              {formatSeconds(Math.floor(seeking ? seekTime : currentTime))}
              {' / '}
              {formatSeconds(Math.floor(currentDuration))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderTracker = () => {
    const { currentTime, currentDuration } = this.props
    const { seeking, seekTime } = this.state
    let width

    if (seeking) {
      width = (seekTime / currentDuration) * 100
    } else {
      width = (currentTime / currentDuration) * 100
    }

    return (
      <div className="audio-player__tracker" style={{ width: `${width}%` }} />
    )
  }

  renderPlayPauseButton = () => {
    const { playing, onPlay, onPause } = this.props

    let icon
    let action

    if (playing) {
      icon = 'fas fa-pause'
      action = onPause
    } else {
      icon = 'fas fa-play'
      action = onPlay
    }

    return (
      <button onClick={action} className="audio-player__play-pause-button">
        <i className={icon} />
      </button>
    )
  }
}
